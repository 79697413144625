import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Input from '../../Components/Input';
import Alert from '../../Components/Alert';
import Loading from '../../Components/LoaderScreen';
import { validaForm } from '../../Utils/FormValidator';
import ApiService from '../../Utils/services';

export default class NewNegotiationDiscount extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            redirect: false,
            path: '',
            propsToPath: '',
            alert: false,
            alertText: '',
            alertType: 0,
            nome: '',
            dias_inicio: '',
            dias_fim: ''
        };
    }

    async componentDidMount() {
        const { faixa } = this.props.location.state;
        const userData = JSON.parse(sessionStorage.getItem('userData'));

        if (faixa.id) {
            this.setState({ loading: true });
            await ApiService.getBackoffice('autonegociacao/faixa/' + faixa.id, userData.token)
                .then(result => {
                    const res = result.data.data;
                    this.setState({ nome: res.faxdesc_nome, dias_inicio: res.faxdesc_diasinicio, dias_fim: res.faxdesc_diasfim, loading: false });
                })
                .catch(err => {
                    this.setState({ path: '/negociacao/faixa-desconto', loading: false, redirect: true });
                })
        }
    }

    validaCampos() {
        const { nome, dias_inicio, dias_fim } = this.state;
        var form = [
            { campo: 'Nome', attr: 'nome', valor: nome },
            { campo: 'Dias Início', attr: 'dias_inicio', valor: dias_inicio },
            { campo: 'Dias Fim', attr: 'dias_fim', valor: dias_fim }
        ];

        const valida = validaForm(form);
        if (valida.valido) {
            this.setState({ loading: true });
            var formData = {
                faxdesc_nome: nome,
                faxdesc_diasinicio: dias_inicio,
                faxdesc_diasfim: dias_fim
            };

            const userData = JSON.parse(sessionStorage.getItem('userData'));
            if (!this.props.location.state.faixa.id) {
                this.novaFaixa(formData, userData.token);
            } else {
                this.alteraFaixa(formData, userData.token)
            }
        }

        if (!valida.valido) {
            for (let item of valida.campos) {
                if (!item.valido) {
                    this.setState({ alertText: '* ' + item.mensagem, alert: true });
                    break;
                }
            }
        }
    }

    async novaFaixa(form, token) {
        const { empresa } = this.props.location.state;
        await ApiService.postBackoffice('autonegociacao/faixa', form, token)
            .then(res => {
                this.setState({ path: '/negociacao/faixa-desconto', loading: false, redirect: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async alteraFaixa(form, token) {
        const { faixa } = this.props.location.state;
        await ApiService.customRequest('backoffice/autonegociacao/faixa/' + faixa.id, 'PUT', form, token)
            .then(res => {
                this.setState({ alertType: 1, alertText: 'A faixa de desconto foi alterada com sucesso!', loading: false, alert: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
        // pmnu_id
    }

    render() {
        const { redirect, path, propsToPath, alert, alertText, alertType, loading, nome, dias_inicio, dias_fim } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Alert show={alert} texto={alertText} tipo={alertType} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={2} />

                <div className="body">
                    <Header />

                    <div className="empresas-usuarios-novo">
                        <h1 style={{ textTransform: 'capitalize' }}>{this.props.location.state.faixa.id ? 'Editar' : 'Nova'} Faixa</h1>
                        <p className="descricao">Abaixo os dados da faixa de desconto</p>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Dados Básicos</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multInput marginInputs" style={{ marginTop: 24 }}>
                                    <Input type="text" label="Nome" value={nome} onChange={e => this.setState({ nome: e.target.value })} />
                                    <Input type="tel" label="Dias Início" value={dias_inicio} onChange={e => this.setState({ dias_inicio: e.target.value })} />
                                    <Input type="tel" label="Dias Fim" value={dias_fim} onChange={e => this.setState({ dias_fim: e.target.value })} />
                                    
                                </div>
                            </div>
                        </div>

                        <div className="acoes">
                            <button className="btn voltar" onClick={() => this.setState({ path: '/negociacao/faixa-desconto', redirect: true })}>{'<'} Voltar</button>
                            <button className="btn btnCadastrar bg-roxo cl-branco" onClick={() => this.validaCampos()}>
                                {this.props.location.state.faixa.id ? 'Alterar' : 'Cadastrar'}
                            </button>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}