import React, { Component, Fragment } from 'react';

import iconDashboard from '../resources/icon-dashboard.svg';
import iconEmpresas from '../resources/icon-empresas.svg';
import iconClientes from '../resources/icon-clientes.svg';
import iconDebitos from '../resources/icon-debitos.svg';
import iconPagamentos from '../resources/icon-pagamentos.svg';
import iconLiquidar from '../resources/icon-liquidar-manual.svg';
import iconRemessa from '../resources/icon-remessa-pagto.svg';
import iconRetornoRemessa from '../resources/icon-retorno-remessa.svg';
import iconLiquidacaoAutomatica from '../resources/icon-liquidacao-automatica.svg';
import iconFaixaDesconto from '../resources/ico-faixa-desconto.png';

import { Link } from 'react-router-dom';

export default class SideMenu extends Component {

    constructor() {
        super();

        this.state = {
            nomeUsuario: ''
        }
    }

    componentDidMount() {
        const userData = sessionStorage.getItem('userData');
        if (userData) {
            const user = JSON.parse(userData);
            this.setState({ nomeUsuario: user.usuario.nome });
        } else {
            this.setState({ nomeUsuario: 'Usuário' })
        }
    }

    render() {
        const { menuAtivo } = this.props;
        const { nomeUsuario } = this.state;
        return (
            <Fragment>
                <div className="sideMenuBar bg-roxo">
                    <div className="profile">
                        <p>Olá, {nomeUsuario}.</p>
                    </div>
                    <div className="navigation">
                        <ul>
                            <li><Link to="/home" className={menuAtivo === 1 ? "active" : ""}><img src={iconDashboard} alt="Ícone da Dashboard" /> Dashboard</Link></li>
                            <li><Link to="/empresas" className={menuAtivo === 2 ? "active" : ""}><img src={iconEmpresas} alt="Ícone de Empresas" /> Empresas</Link></li>
                            <li><Link to="/negociacao/faixa-desconto" className={menuAtivo === 11 ? "active" : ""}><img src={iconFaixaDesconto} alt="Ícone de Faixa de Desconto" /> Negociação (Faixa Desconto)</Link></li>
                            <li><Link to="/clientes" className={menuAtivo === 3 ? "active" : ""}><img src={iconClientes} alt="Ícone dos Clientes" /> Clientes</Link></li>
                            <li><Link to="/debitos" className={menuAtivo === 4 ? "active" : ""}><img src={iconDebitos} alt="Ícone de Débitos" /> Débitos</Link></li>
                            <li><Link to="/consultar-cobranca" className={menuAtivo === 9 ? "active" : ""}><img src={iconClientes} alt="Ícone de Consulta Cobrança" /> Consulta Cobrança</Link></li>
                            <li><Link to="/pagamentos" className={menuAtivo === 5 ? "active" : ""}><img src={iconPagamentos} alt="Ícone de Pagamentos" /> Pagamentos</Link></li>
                            <li><Link to="/liquidar-manual" className={menuAtivo === 6 ? "active" : ""}><img src={iconLiquidar} alt="Ícone de Liquidar Manual" /> Liquidar Manual</Link></li>
                            <li><Link to="/liquidacao-automatica" className={menuAtivo === 10 ? "active" : ""}><img src={iconLiquidacaoAutomatica} alt="Ícone de Liquidação Automática" /> Liquidação Automática</Link></li>
                            <li><Link to="/remessa/gerar" className={menuAtivo === 7 ? "active" : ""}><img src={iconRemessa} alt="Ícone de Remesa" /> Remessa de Pagto</Link></li>
                            <li><Link to="/remessa/retorno" className={menuAtivo === 8 ? "active noBorder" : "noBorder"}><img src={iconRetornoRemessa} alt="Ícone de Processar Retorno" /> Retorno Remessa</Link></li>
                        </ul>
                    </div>
                </div>
            </Fragment>
        )
    }
}